<template>

    <b-modal v-model="openedModal" centered :title="`${(type === 'add' ? 'Dodaj' : 'Uredi')} kategorijo`" hide-footer>
        <b-overlay :show="showLoader">

            <b-row>
                <b-col>
                    <validation-observer ref="validate">
                        <b-form>
                            <validation-provider #default="{ errors }" name="naslov kategorije" rules="required|max:30">
                                <b-form-group>
                                    <label>Naslov:</label>
                                    <b-form-input v-model="category.title" placeholder="NASLOV"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider #default="{ errors }" name="bbis id" rules="required|max:30">
                                <b-form-group>
                                    <label>BBIS ID:</label>
                                    <b-form-input v-model="category.bbis_id" placeholder="BBIS ID"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider #default="{ errors }" name="cena pošiljanja" rules="required|min_value:0">
                                <b-form-group>
                                    <label>Cena pošiljanja:</label>
                                    <b-form-input step="0.01" type="number" :value="shippingPrice" v-on:input="changePrice" placeholder="Cena pošiljanja"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col class="text-center">
                    <ImageSelector ref="parentCategoryImageSelector" 
                                   :main_photo="category.main_photo" 
                                   :additional_photos="category.additional_photos" 
                                   @update-main-photo="updateMainPhoto" 
                                   :type="type"
                                   :additional_photos_enabled="false" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="secondary" @click="validateForm">{{ (type === 'add' ? 'DODAJ' : 'UREDI') }}</b-button>
                </b-col>
            </b-row>
        </b-overlay>
    </b-modal>

</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, max, minValue } from '@validations'
    import ImageSelector from '@/views/Components/ImageSelector'
    import {BButton, BForm, BFormGroup, BFormInput, BModal, BRow, BCol, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            ImageSelector,
            BModal,
            BForm,
            BFormGroup,
            BFormInput,
            BButton,
            BRow,
            BCol,
            BOverlay
        },
        props: {
            type: {
                type: String,
                reqired: true
            },
            category: {
                type: Object,
                reqired: true
            }
        },
        data() {
            return {
                openedModal: false,
                showLoader: false,
                required, 
                max,
                minValue,
                shippingPrice : 0
            }
        },
        methods: {
            openModal() {
                this.openedModal = true
            },
            changePrice(val) {
                this.shippingPrice = val
                this.category.shipping_price = Number(Number(val * 100).toFixed(0))
            },
            async validateForm() {
                const res = await this.$refs.validate.validate()
                if (res) {
                    if (this.type === 'add') await this.addCategory()
                    else if (this.type === 'edit') await this.editCategory()
                }
                
            },
            async addCategory() {

                this.showLoader = true
                const isImageUploadSuccessfull = await this.$refs.parentCategoryImageSelector.UploadImagesToAPI()
                if (!isImageUploadSuccessfull) {
                    this.$printError('Prišlo je do napake pri nalaganju slik.')
                    this.showLoader = false
                    return
                }
                if (this.category.main_photo === '') {
                    this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                    this.showLoader = false
                    return
                }

                // if it happens, that photo is pushed to additional_photos, just empty array,
                // because only main_photo must be populated
                this.category.additional_photos = []

                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/shop/category/', this.category)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Kategorija je dodana')
                    thisIns.category.title = ''
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(() => {
                    this.showLoader = false
                })
            },
            async editCategory() {

                this.showLoader = true
                const isImageUploadSuccessfull = await this.$refs.parentCategoryImageSelector.UploadImagesToAPI()
                if (!isImageUploadSuccessfull) {
                    this.$printError('Prišlo je do napake pri nalaganju slik.')
                    this.showLoader = false
                    return
                }
                if (this.category.main_photo === '') {
                    this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                    this.showLoader = false
                    return
                }

                // if it happens, that photo is pushed to additional_photos, just empty array,
                // because only main_photo must be populated
                this.category.additional_photos = []

                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/shop/category/${this.category.id}`, this.category)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Kategorija je urejena')
                    thisIns.category.title = ''
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(() => {
                    this.showLoader = false
                })
            },
            updateMainPhoto(main_photo_id) {
                this.category.main_photo = main_photo_id
            }
        },
        watch: {
            category(val) {
                this.shippingPrice = val.shipping_price / 100
            },
            type(val) {
                if (val === 'edit') this.shippingPrice = this.category.shipping_price / 100
                else this.shippingPrice = 0
            }
        }
    }
</script>

<style scoped>

</style>