<template>
    <b-overlay :show="!dataLoaded">
        <b-card>
            <b-button variant="primary" @click="addCategory" v-if="$hasPermissions($permissions.EditWebShopCategory)">Dodaj kategorijo</b-button>
            <b-button variant="danger" @click="syncCategories" class="float-right" v-if="$hasPermissions($permissions.EditWebShopCategory)">Sinhroniziraj kategorije z BBIS</b-button>
            <b-col v-for="category of categories" :key="category.id">
                <b-card v-if="!category.parent_category_id" no-body border-variant="light" style="border-width: 3px !important;" class="mt-1">
                    <b-card-header style="background-color: #f6f6f6" header-tag="header" class="p-1" role="tab">
                        <b-row align="left" class="w-100">
                            <b-col cols="12" md="10" class="w-100">
                                <b-button class="text-left" block v-b-toggle :href="'#category-' + category.id" @click.prevent variant="grey">
                                    <b-row class="d-flex align-items-center">
                                        <b-col cols="12" lg="6" class="justify-content-start">
                                            <img :src="`/api/management/v1/image/thumbnail_${category.main_photo}`" />
                                        </b-col>
                                        <b-col cols="12" lg="6" class="px-1 px-md-3 py-1 py-md-2 py-xl-0 d-flex flex-column justify-content-center justify-content-xl-start">
                                            <p style="font-size: 16px; font-weight: bold;" class="d-flex text-left">{{category.title}}</p>
                                            <p style="font-size: 14px;" class="d-flex text-left">BBIS ID: {{category.bbis_id}}</p>
                                            <p style="font-size: 14px;" class="mb-0 d-flex text-left">Cena pošiljanja: {{$convertPriceIntegerToFloat(category.shipping_price)}} €</p>
                                        </b-col>
                                    </b-row>
                                </b-button>
                            </b-col>
                            <b-col cols="12" md="2" align="right" class="d-flex justify-content-center justify-content-lg-end align-items-center">
                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" class="mr-1" variant="warning" @click="editCategory(category)"><fa icon="edit"/></b-button>
                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" @click="deleteCategory(category.id)" variant="danger"><fa icon="trash"/></b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="'category-' + category.id" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <template  v-if="category.children && category.children.length">

                                <b-list-group flush>
                                    <b-list-group-item v-for="(childCategory, index) of category.children" :key="index">
                                        <b-row>
                                            <b-col cols="12" md="8" align="left" class="ml-2">
                                                <b-row class="d-flex align-items-center">
                                                    <b-col cols="12" lg="6">
                                                        <img :src="`/api/management/v1/image/${childCategory.main_photo}`" />
                                                    </b-col>
                                                    <b-col cols="12" lg="6" class="px-3 py-2 py-xl-0 d-flex flex-column justify-content-center justify-content-xl-start">
                                                        <p style="font-size: 16px; font-weight: bold;" class="d-flex text-left">{{childCategory.title}}</p>
                                                        <p style="font-size: 14px;" class="d-flex text-left">BBIS ID: {{childCategory.bbis_id}}</p>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" md="3" align="right" class="d-flex justify-content-center justify-content-lg-end align-items-center">
                                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" size="sm" class="mr-1" variant="warning" @click="editChildrenCategory(childCategory)"><fa icon="edit"/></b-button>
                                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" size="sm" @click="deleteCategory(childCategory.id)" variant="danger"><fa icon="trash"/></b-button>
                                            </b-col>
                                        </b-row>
                                        <b-collapse :id="'category-' + category.id" visible accordion="my-accordion" role="tabpanel">
                                            <b-row>
                                                <b-list-group flush>
                                                    <b-list-group-item v-for="(childCategory, index1) of childCategory.children" :key="index1">
                                                        <b-row>
                                                            <b-col cols="12" md="8" align="left" class="ml-2">
                                                                <b-row class="d-flex align-items-center">
                                                                    <b-col cols="12" lg="6">
                                                                        <img :src="`/api/management/v1/image/${childCategory.main_photo}`" />
                                                                    </b-col>
                                                                    <b-col cols="12" lg="6" class="px-3 py-2 py-xl-0 d-flex flex-column justify-content-center justify-content-xl-start">
                                                                        <p style="font-size: 16px; font-weight: bold;" class="d-flex text-left">{{childCategory.title}}</p>
                                                                        <p style="font-size: 14px;" class="d-flex text-left">BBIS ID: {{childCategory.bbis_id}}</p>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-col>
                                                            <b-col cols="12" md="3" align="right" class="d-flex justify-content-center justify-content-lg-end align-items-center">
                                                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" size="sm" class="mr-1" variant="warning" @click="editChildrenCategory(childCategory)"><fa icon="edit"/></b-button>
                                                                <b-button v-if="$hasPermissions($permissions.EditWebShopCategory)" size="sm" @click="deleteCategory(childCategory.id)" variant="danger"><fa icon="trash"/></b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-row>
                                        </b-collapse>
                                    </b-list-group-item>
                                </b-list-group>
                            </template>
                            <template  v-else>

                                <b-alert class="p-1" show variant="warning">Ta kategorija še ne vsebuje pod kategorij</b-alert>
                            </template>
                        </b-card-body>
                        <b-card-body>
                            <b-button variant="primary" @click="addChildrenCategory(category.id)">Dodaj podkategorijo</b-button>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-card>
        <WebShopCategoryModal ref="categoryModal" :type="type" :category="category" v-on:success="getCategories"/>
        <ChildrenWebShopCategoryModal ref="childrenCategoryModal" :type="type" :category="category" :parent-id="parentId" v-on:success="getCategories"/>
    </b-overlay>
</template>
<script>
    import WebShopCategoryModal from './WebShopCategoryModal'
    import ChildrenWebShopCategoryModal from './ChildrenWebShopCategoryModal'
    import {
        BAlert,
        BButton,
        BCard,
        BCardBody,
        BCardHeader,
        BCol, BCollapse,
        BListGroup,
        BListGroupItem,
        BOverlay,
        BRow, VBToggle
    } from 'bootstrap-vue'
    import Vue from 'vue'
    export default {
        components: {
            WebShopCategoryModal,
            ChildrenWebShopCategoryModal,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BCardBody,
            BListGroup,
            BListGroupItem,
            BCardHeader,
            BAlert,
            BCollapse
        },
        data() {
            return {
                dataLoaded: false,
                categories: [],
                addCategoryModal: false,
                editCategoryModal: false,
                addchildrenCategoryModal: false,
                editchildrenCategoryModal: false,
                categoryName: '',
                childrenCategoryName: '',
                category: {title: '', bbis_id: '', parent_category_id: '', main_photo: '', shipping_price: 0},
                buttonTitle: 'Dodaj',
                edit: false,
                type: '',
                parentId: ''
            }
        },
        methods: {
            getCategories() {
                const thisIns = this
                thisIns.dataLoaded = false
                const loadPromise = this.$http.get('/api/management/v1/shop/category/')
                loadPromise.then(function(response) {
                    thisIns.categories = response.data
                    thisIns.categories.map(cat => {
                        cat.additional_photos = []

                        cat.children.map(child => {
                            child.additional_photos = []
                        })
                    })

                    thisIns.dataLoaded = true
                }).catch(function(error) {
                    thisIns.dataLoaded = true
                    thisIns.$printError(error.response.data)
                })
            },
            addCategory() {
                this.type = 'add'
                this.category = {
                    title: '',
                    bbis_id: '',
                    main_photo: '',
                    additional_photos: []
                }
                this.$refs.categoryModal.openModal()
            },
            editCategory(category) {
                this.type = 'edit'
                const category_copy = JSON.parse(JSON.stringify(category))
                this.category = category_copy
                this.category.additional_photos = []
                this.$refs.categoryModal.openModal()
            },
            addChildrenCategory(id) {
                this.type = 'add'
                this.category = {
                    title: '',
                    bbis_id: '',
                    main_photo: '',
                    additional_photos: []
                }
                this.parentId = id
                this.$refs.childrenCategoryModal.openModal()
            },
            editChildrenCategory(category) {
                this.type = 'edit'
                const category_copy = JSON.parse(JSON.stringify(category))
                this.category = category_copy
                this.category.additional_photos = []
                this.$refs.childrenCategoryModal.openModal()
            },
            syncCategories() {
                const thisIns = this
                thisIns.dataLoaded = false
                const syncPromise = this.$http.post('/api/management/v1/shop/category_sync')
                syncPromise.then(() => {
                    const loadPromise = this.$http.get('/api/management/v1/shop/category/')
                    loadPromise.then(function(response) {
                        thisIns.categories = response.data
                        thisIns.categories.map(cat => {
                            cat.additional_photos = []

                            cat.children.map(child => {
                                child.additional_photos = []
                            })
                        })
                    })
                }
                )
                thisIns.dataLoaded = true
            },
            deleteCategory(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati kategorijo.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        const loadPromise = this.$http.delete(`/api/management/v1/shop/category/${id}`)
                        loadPromise.then(function() {
                            thisIns.$printSuccess('Kategorija je izbrisana')
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.getCategories()
                        })
                    }
                })
            }
        },
        created() {
            Vue.directive('b-toggle', VBToggle)
            this.getCategories()
        }
    }
</script>
<style scoped>
img {
  max-width: 300px !important;
  width: 100%;
  object-fit: contain;
}
</style>
